import { ACCOUNT_STATEMENT } from "./types";

const initialState = {
  accountStatement: null,
};

export default (state = initialState, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case ACCOUNT_STATEMENT:
      let data = payload.sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return {
        ...state,
        accountStatement: data,
      };
    default:
      return state;
  }
};
