import io from "socket.io-client";
import { getLocalToken } from "./axiosService";

export const socket = io(process.env.REACT_APP_SOCKET_URL, {
  path: "/public/",
  transports: ["websocket", "polling"],
  auth: {
    token: localStorage.getItem("token"),
  },
});

export const asyncSocketEmit = (eventName, data) => {
  return new Promise(function (resolve, reject) {
    socketFunc(getLocalToken()).emit("get:betby_details", (res) => {
      if (res.data && res?.data?.data) {
        resolve(res?.data?.data);
      }
      if (res.error) {
        reject(res.error);
      }
    });
    // setTimeout(reject("request timeout"), 3000);
  });
};

export const socketFunc = (token) =>
  io(process.env.REACT_APP_SOCKET_URL, {
    path: "/public/",
    transports: ["websocket", "polling"],
    auth: {
      token: token,
    },
  });

export const sportsSocket = io(process.env.REACT_APP_SPORTS_SOCKET, {
  path: "/public/",
  transports: ["websocket", "polling"],
});
