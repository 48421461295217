import axios from "axios";
const userToken = localStorage.getItem("token");
export const base_url = process.env.REACT_APP_API_HOST;
let withoutToken = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

if (userToken !== null) {
  withoutToken = { ...withoutToken, Authorization: userToken };
}

export const axiosService = axios.create({
  baseURL: base_url,
  headers: withoutToken,
});

/**
 *
 * @param token
 */
export const setUserToken = (token) => {
  if (token) {
    axiosService.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axiosService.defaults.headers.common["Authorization"] = token;
  } else {
    axiosService.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }
};

export const getLocalToken = () => localStorage.getItem("token");
