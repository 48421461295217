import React from 'react'

export default function RummyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="39.007" viewBox="0 0 53 39.007">
  <g id="Group_9472" data-name="Group 9472" transform="translate(0 0)">
    <g id="Group_9415" data-name="Group 9415" transform="translate(0 0.7)">
      <g id="Group_9407" data-name="Group 9407">
        <g id="Group_9405" data-name="Group 9405" transform="translate(0 6.22)">
          <path id="Path_11131" data-name="Path 11131" d="M1151.053,1257.723a2.536,2.536,0,0,1-2.057-1.045l-16.418-22.786a2.535,2.535,0,0,1,.572-3.529l6.366-4.587-.114.922a1.707,1.707,0,0,0,.084.785l9.424,26.454a1.719,1.719,0,0,0,2.194,1.042l9.039-3.219-7.615,5.488A2.518,2.518,0,0,1,1151.053,1257.723Zm-1.934-2.262.534.741a1.726,1.726,0,0,0,2.4.388l2.984-2.151-3.662,1.3A2.527,2.527,0,0,1,1149.12,1255.461Zm-10.492-28.044-5,3.6a1.722,1.722,0,0,0-.388,2.4l14.838,20.592-9.353-26.256A2.484,2.484,0,0,1,1138.628,1227.417Z" transform="translate(-1132.104 -1225.777)" fill="#6e6e6e"/>
        </g>
        <g id="Group_9406" data-name="Group 9406" transform="translate(7.764)">
          <path id="Path_11132" data-name="Path 11132" d="M1155,1213.4l7.619-5.489a2.527,2.527,0,0,1,3.528.572l.544.755Zm9.093-5.156a1.7,1.7,0,0,0-1,.325l-2.987,2.152,5.29-1.883a1.7,1.7,0,0,0-1.024-.571A1.736,1.736,0,0,0,1164.093,1208.245Z" transform="translate(-1155 -1207.434)" fill="#6e6e6e"/>
        </g>
      </g>
      <g id="Group_9410" data-name="Group 9410" transform="translate(2.165 10.984)">
        <g id="Group_9408" data-name="Group 9408" transform="translate(0 1.36)">
          <path id="Path_11133" data-name="Path 11133" d="M1141.026,1246.135s-.213.126-.512.341c-.077.054-.494.385-.494.385l-.157-.174.331-.329a3.262,3.262,0,0,0-.174-.31l-1.048-1.455-.272.531-.212-.085s.192-.663.223-.778a2.764,2.764,0,0,0,.021-.341l.209-.083s.473.712.609.9l.7.975a3.891,3.891,0,0,0,.313.38l.327-.2Z" transform="translate(-1138.488 -1243.836)" fill="#6e6e6e"/>
        </g>
        <g id="Group_9409" data-name="Group 9409" transform="translate(1.62)">
          <path id="Path_11134" data-name="Path 11134" d="M1143.69,1240.028c.654-.468,1.327-.064,1.817.615s.658,1.444.007,1.913-1.326.064-1.814-.613S1143.042,1240.5,1143.69,1240.028Zm.163.225c-.442.32-.128.94.219,1.422s.835.976,1.278.656.129-.94-.219-1.42S1144.3,1239.933,1143.853,1240.253Z" transform="translate(-1143.266 -1239.826)" fill="#6e6e6e"/>
        </g>
      </g>
      <g id="Group_9411" data-name="Group 9411" transform="translate(4.516 14.697)">
        <path id="Path_11135" data-name="Path 11135" d="M1147.916,1250.775c-1.062-.015-.908,1.334-.908,1.334s-1.232-.571-1.553.44c-.195.611.487,1.093,1.25,1.335a1.292,1.292,0,0,0,2.082-1.5C1148.8,1251.586,1148.556,1250.786,1147.916,1250.775Z" transform="translate(-1145.421 -1250.775)" fill="#6e6e6e"/>
      </g>
      <g id="Group_9414" data-name="Group 9414" transform="translate(6.464 10.022)">
        <g id="Group_9412" data-name="Group 9412">
          <path id="Path_11136" data-name="Path 11136" d="M1152.4,1236.989l-1.234.889.369.514,1.086-.783Z" transform="translate(-1151.166 -1236.989)" fill="#6e6e6e"/>
        </g>
        <g id="Group_9413" data-name="Group 9413" transform="translate(0.999 8.083)">
          <path id="Path_11137" data-name="Path 11137" d="M1159.769,1267.962l-5.145-7.136-.512.369,6.419,8.908Z" transform="translate(-1154.113 -1260.826)" fill="#6e6e6e"/>
        </g>
      </g>
    </g>
    <g id="Group_9423" data-name="Group 9423" transform="translate(9.354)">
      <g id="Group_9416" data-name="Group 9416" transform="translate(8.928)">
        <path id="Path_11138" data-name="Path 11138" d="M1194.257,1206.145l.913.209a2.119,2.119,0,0,0-2.5-.862l-6.654,2.369,5.732-.249A2.124,2.124,0,0,1,1194.257,1206.145Z" transform="translate(-1186.016 -1205.369)" fill="#6e6e6e"/>
      </g>
      <g id="Group_9418" data-name="Group 9418" transform="translate(0 6.851)">
        <g id="Group_9417" data-name="Group 9417">
          <path id="Path_11139" data-name="Path 11139" d="M1160.572,1225.976a3.439,3.439,0,0,0,.132.474l.853,2.4a3.952,3.952,0,0,1-.3,1.044l-.181-.046a8.247,8.247,0,0,0,.052-.95l-.867-2.432a2.74,2.74,0,0,0-.157-.319l-.355.066-.059-.244s.425-.127.511-.157c.352-.125.592-.237.592-.237l.108.253Z" transform="translate(-1159.688 -1225.572)" fill="#6e6e6e"/>
        </g>
      </g>
      <g id="Group_9419" data-name="Group 9419" transform="translate(0.469 11.02)">
        <path id="Path_11140" data-name="Path 11140" d="M1164.046,1237.913c-1.017-.311-1.246,1.027-1.246,1.027s-1.023-.893-1.612-.01c-.355.532.163,1.187.829,1.631a1.291,1.291,0,0,0,2.416-.862C1164.669,1238.936,1164.655,1238.1,1164.046,1237.913Z" transform="translate(-1161.072 -1237.866)" fill="#6e6e6e"/>
      </g>
      <g id="Group_9422" data-name="Group 9422" transform="translate(2.826 6.664)">
        <g id="Group_9420" data-name="Group 9420" transform="translate(1.145)">
          <path id="Path_11141" data-name="Path 11141" d="M1173.152,1225.682l-.029-.661-1.723.614.213.6Z" transform="translate(-1171.401 -1225.021)" fill="#6e6e6e"/>
        </g>
        <g id="Group_9421" data-name="Group 9421" transform="translate(0 7.943)">
          <path id="Path_11142" data-name="Path 11142" d="M1171.6,1256.819l-2.981-8.374-.6.212,3.67,10.307Z" transform="translate(-1168.023 -1248.446)" fill="#6e6e6e"/>
        </g>
      </g>
    </g>
    <g id="Group_9432" data-name="Group 9432" transform="translate(14.558 1.814)">
      <g id="Group_9425" data-name="Group 9425">
        <g id="Group_9424" data-name="Group 9424">
          <path id="Path_11143" data-name="Path 11143" d="M1178.777,1244.157a2.527,2.527,0,0,1-2.522-2.42l-1.218-28.056a2.528,2.528,0,0,1,2.418-2.633l7.587-.329-.171.575c-.007.024-.015.047-.021.072l-6.266,27.376a1.716,1.716,0,0,0,1.29,2.055h0l12.168,2.787-13.153.571Zm5.193-32.58-6.48.281a1.717,1.717,0,0,0-1.642,1.788l1.218,28.056a1.715,1.715,0,0,0,1.788,1.642l7.153-.311-6.314-1.446a2.527,2.527,0,0,1-1.9-3.027Z" transform="translate(-1175.034 -1210.718)" fill="#6e6e6e"/>
        </g>
      </g>
      <g id="Group_9427" data-name="Group 9427" transform="translate(2.369 2.128)">
        <g id="Group_9426" data-name="Group 9426">
          <path id="Path_11144" data-name="Path 11144" d="M1183.787,1220.384c-.056.007-.108.014-.171.017-1,.042-1.549-.63-1.594-1.636s.442-1.726,1.448-1.768,1.549.626,1.594,1.636a1.539,1.539,0,0,1-.926,1.671l.414.265c.077.049.749.3.749.3l-.258.438Zm-.181-.23c.693-.03,1-.693.961-1.5s-.4-1.445-1.086-1.415-1,.693-.961,1.5S1182.916,1220.185,1183.606,1220.154Z" transform="translate(-1182.02 -1216.995)" fill="#6e6e6e"/>
        </g>
      </g>
      <g id="Group_9428" data-name="Group 9428" transform="translate(1.984 6.687)">
        <path id="Path_11145" data-name="Path 11145" d="M1184.17,1230.6c-.877-.6-1.49.615-1.49.615s-.717-1.152-1.539-.484c-.5.405-.195,1.183.31,1.8a4.7,4.7,0,0,0,1.333,1.084,4.76,4.76,0,0,0,1.232-1.2C1184.462,1231.765,1184.7,1230.964,1184.17,1230.6Z" transform="translate(-1180.887 -1230.438)" fill="#6e6e6e"/>
      </g>
      <g id="Group_9431" data-name="Group 9431" transform="translate(3.576 3.305)">
        <g id="Group_9429" data-name="Group 9429" transform="translate(0 6.926)">
          <path id="Path_11146" data-name="Path 11146" d="M1186.687,1251.91l-.478-11.021-.63.028.575,13.319Z" transform="translate(-1185.579 -1240.889)" fill="#6e6e6e"/>
        </g>
        <g id="Group_9430" data-name="Group 9430" transform="translate(3.314)">
          <path id="Path_11147" data-name="Path 11147" d="M1197.107,1221.1l.147-.641-1.9.082.028.634Z" transform="translate(-1195.352 -1220.464)" fill="#6e6e6e"/>
        </g>
      </g>
    </g>
    <g id="Group_9448" data-name="Group 9448" transform="translate(17.252 0.316)">
      <g id="Group_9437" data-name="Group 9437">
        <g id="Group_9434" data-name="Group 9434" transform="translate(20.418 2.597)">
          <g id="Group_9433" data-name="Group 9433">
            <path id="Path_11148" data-name="Path 11148" d="M1251.38,1218.351l-8.186-4.392,6.164,1.412a2.523,2.523,0,0,1,1.951,2.228Zm-2.486-2.255,1.24.666a1.711,1.711,0,0,0-.957-.6Z" transform="translate(-1243.194 -1213.959)" fill="#6e6e6e"/>
          </g>
        </g>
        <g id="Group_9436" data-name="Group 9436">
          <g id="Group_9435" data-name="Group 9435">
            <path id="Path_11149" data-name="Path 11149" d="M1197.641,1241.576l-12.7-2.908a2.527,2.527,0,0,1-1.9-3.027l6.267-27.377a2.53,2.53,0,0,1,3.03-1.9l7.113,1.629-.312.518c-.008.013-.017.026-.024.04l-13.274,24.745a1.716,1.716,0,0,0,.7,2.325h0Zm-5.866-34.463a1.721,1.721,0,0,0-1.673,1.333l-6.266,27.376a1.716,1.716,0,0,0,1.29,2.055l6.816,1.561-5.778-3.1a2.528,2.528,0,0,1-1.033-3.423l13.075-24.375-6.044-1.384A1.72,1.72,0,0,0,1191.774,1207.113Z" transform="translate(-1182.98 -1206.301)" fill="#6e6e6e"/>
          </g>
        </g>
      </g>
      <g id="Group_9440" data-name="Group 9440" transform="translate(8.056 1.943)">
        <g id="Group_9439" data-name="Group 9439">
          <g id="Group_9438" data-name="Group 9438">
            <path id="Path_11150" data-name="Path 11150" d="M1208.586,1212.564l-.359-.049a4.543,4.543,0,0,0-.142.472l-.383,1.676c-.042.191-.07.421-.077.487l.4.125-.09.257s-.292-.09-.654-.174c-.091-.019-.54-.1-.54-.1l.031-.233.372.01a2.925,2.925,0,0,0,.118-.334l.449-1.948c.014-.079.031-.292.035-.353l-.334-.134.077-.239s.432.122.522.143c.358.084.623.118.623.118Zm1.017,3.352s-.268-.085-.63-.167c-.09-.021-.585-.112-.585-.112l.031-.23.279,0c-.042-.132-.153-.428-.206-.546-.073-.129-.553-1.026-.553-1.026l1.058-.823.244-.268-.23-.1.076-.24s.383.111.474.132c.362.084.581.11.581.11l-.045.27-.351-.045c-.139.08-.393.233-.56.337l-.78.658c.234.435.477.874.7,1.309.052.087.192.272.278.387l.31.1Z" transform="translate(-1206.737 -1212.031)" fill="#6e6e6e"/>
          </g>
        </g>
      </g>
      <g id="Group_9442" data-name="Group 9442" transform="translate(6.99 6.348)">
        <g id="Group_9441" data-name="Group 9441">
          <path id="Path_11151" data-name="Path 11151" d="M1206.981,1225.783c-.689-.808-1.6.2-1.6.2s-.383-1.3-1.354-.874c-.585.257-.5,1.09-.178,1.819a4.753,4.753,0,0,0,1,1.4,4.687,4.687,0,0,0,1.508-.827C1206.953,1226.981,1207.392,1226.271,1206.981,1225.783Z" transform="translate(-1203.593 -1225.02)" fill="#6e6e6e"/>
        </g>
      </g>
      <g id="Group_9447" data-name="Group 9447" transform="translate(5.076 4.201)">
        <g id="Group_9444" data-name="Group 9444" transform="translate(7.641)">
          <g id="Group_9443" data-name="Group 9443">
            <path id="Path_11152" data-name="Path 11152" d="M1222.2,1219.051l-1.582-.362-.139.616,1.411.323Z" transform="translate(-1220.483 -1218.689)" fill="#6e6e6e"/>
          </g>
        </g>
        <g id="Group_9446" data-name="Group 9446" transform="translate(0 5.744)">
          <g id="Group_9445" data-name="Group 9445">
            <path id="Path_11153" data-name="Path 11153" d="M1199.083,1245.587l2.247-9.818-.616-.141-2.764,12.071Z" transform="translate(-1197.95 -1235.628)" fill="#6e6e6e"/>
          </g>
        </g>
      </g>
    </g>
    <g id="Group_9449" data-name="Group 9449" transform="translate(19.506 1.255)">
      <path id="Path_11154" data-name="Path 11154" d="M1209.191,1245.3a2.125,2.125,0,0,1-2.875.868l-15.568-8.353a2.122,2.122,0,0,1-.867-2.874l13.274-24.746a2.125,2.125,0,0,1,2.875-.868l15.568,8.353a2.122,2.122,0,0,1,.867,2.874Z" transform="translate(-1189.628 -1209.071)" fill="none"/>
    </g>
    <g id="Group_9451" data-name="Group 9451" transform="translate(19.1 0.851)">
      <g id="Group_9450" data-name="Group 9450">
        <path id="Path_11155" data-name="Path 11155" d="M1206.523,1246.036a2.518,2.518,0,0,1-1.192-.3l-15.568-8.353a2.528,2.528,0,0,1-1.033-3.423l13.274-24.746a2.537,2.537,0,0,1,3.424-1.034l15.568,8.353a2.532,2.532,0,0,1,1.033,3.423l-13.274,24.746a2.511,2.511,0,0,1-1.5,1.226A2.549,2.549,0,0,1,1206.523,1246.036Zm-2.284-37.345a1.726,1.726,0,0,0-1.52.906l-13.274,24.746a1.717,1.717,0,0,0,.7,2.325l15.568,8.353a1.719,1.719,0,0,0,2.326-.7h0l13.274-24.746a1.719,1.719,0,0,0-.7-2.325l-15.568-8.353A1.709,1.709,0,0,0,1204.239,1208.69Z" transform="translate(-1188.429 -1207.88)" fill="#d1a132"/>
      </g>
    </g>
    <g id="Group_9453" data-name="Group 9453" transform="translate(33.388 3.307)">
      <g id="Group_9452" data-name="Group 9452">
        <path id="Path_11156" data-name="Path 11156" d="M1233.193,1218.79l-.411-.221c-.129-.068-.6-.294-.6-.294l.09-.207.341.078.126-.5.049-.293-.9-.48-.358.358-.181.237.265.193-.139.211-.352-.189c-.149-.082-.56-.275-.56-.275l.09-.211.292.069.393-.309,1.925-1.835.261.087-.4,2.763-.028.433.233.177Zm-1.118-2.083.752.383.209-1.314Z" transform="translate(-1230.565 -1215.121)" fill="#d1a132"/>
      </g>
    </g>
    <g id="Group_9454" data-name="Group 9454" transform="translate(31.854 6.769)">
      <path id="Path_11157" data-name="Path 11157" d="M1229.406,1226.777c-.452-.961-1.6-.233-1.6-.233s-.024-1.358-1.076-1.2c-.633.094-.769.919-.655,1.708a4.746,4.746,0,0,0,.592,1.612,4.643,4.643,0,0,0,1.671-.4C1229.065,1227.924,1229.678,1227.355,1229.406,1226.777Z" transform="translate(-1226.041 -1225.33)" fill="#d1a132"/>
    </g>
    <g id="Group_9455" data-name="Group 9455" transform="translate(32.398 14.738)">
      <path id="Path_11158" data-name="Path 11158" d="M1236.6,1252.682c-1.2-2.559-4.254-.62-4.254-.62s-.07-3.613-2.865-3.2c-1.685.251-2.043,2.447-1.737,4.55a3.443,3.443,0,0,0,6.026,3.231C1235.695,1255.741,1237.324,1254.225,1236.6,1252.682Z" transform="translate(-1227.645 -1248.83)" fill="#d1a132"/>
    </g>
    <g id="Group_9457" data-name="Group 9457" transform="translate(35.898 33.101)">
      <g id="Group_9456" data-name="Group 9456">
        <path id="Path_11159" data-name="Path 11159" d="M1240.888,1304.641l-.279-.1-.345.261-2.082,1.862-.216-.167.463-2.622.042-.5-.219-.207.122-.192s.39.235.543.315l.352.19-.1.231-.31-.113-.1.28-.1.5.9.484.216-.2.348-.383-.254-.242.122-.192s.449.267.574.334l.414.221Zm-2.5,1.306.978-.9-.734-.416Z" transform="translate(-1237.967 -1302.984)" fill="#d1a132"/>
      </g>
    </g>
    <g id="Group_9458" data-name="Group 9458" transform="translate(36.727 29.928)">
      <path id="Path_11160" data-name="Path 11160" d="M1243.15,1296.947c-1.052.157-1.079-1.2-1.079-1.2s-1.142.727-1.594-.233c-.271-.58.341-1.149,1.062-1.488a1.293,1.293,0,0,1,2.263,1.214C1243.916,1296.028,1243.784,1296.853,1243.15,1296.947Z" transform="translate(-1240.411 -1293.626)" fill="#d1a132"/>
    </g>
    <g id="Group_9459" data-name="Group 9459" transform="translate(38.081 6.064)">
      <path id="Path_11161" data-name="Path 11161" d="M1254.962,1229.471h0l-9.117,17,.557.3,9.417-17.552-11.115-5.961-.3.557Z" transform="translate(-1244.404 -1223.252)" fill="#d1a132"/>
    </g>
    <g id="Group_9460" data-name="Group 9460" transform="translate(22.606 10.278)">
      <path id="Path_11162" data-name="Path 11162" d="M1199.626,1252.974h0l9.117-17-.557-.3-9.417,17.552,11.116,5.962.3-.557Z" transform="translate(-1198.77 -1235.679)" fill="#d1a132"/>
    </g>
  </g>
</svg>
  )
}