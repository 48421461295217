import React, { Suspense, useEffect } from "react";
import "./App.scss";
import Footer from "./containers/Footer";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./routing/ProtectedRoutes";
import PublicRoutes from "./routing/PublicRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getAppDetails } from "./redux/app/actions";
import PublicSportsBook from "./views/pages/privatePages/sportsBook";

const Contact = React.lazy(() =>
  import("./views/pages/publicPages/footerOptions/Contact")
);
const FairPlay = React.lazy(() =>
  import("./views/pages/publicPages/footerOptions/FairPlay")
);
const GameRule = React.lazy(() =>
  import("./views/pages/publicPages/footerOptions/GameRule")
);
const Policy = React.lazy(() => import("./views/pages/publicPages/footerOptions/Policy"));
const Responsible = React.lazy(() =>
  import("./views/pages/publicPages/footerOptions/Responsible")
);
const TermsCondition = React.lazy(() =>
  import("./views/pages/publicPages/footerOptions/TermsCondition")
);

const Home = React.lazy(() => import("./views/pages/privatePages/home"));
const Games = React.lazy(() => import("./views/pages/privatePages/games"));
const DynamicCasinoPage = React.lazy(() =>
  import("./views/pages/privatePages/games/DynamicCasinoPage")
);
const BetbyPage = React.lazy(() => import("./views/pages/privatePages/betby"));
const ExchangeFund9Wicket = React.lazy(() =>
  import("./views/components/ExchangeFund9Wicket")
);
const Cashier = React.lazy(() => import("./views/pages/privatePages/cashier"));
const LandingPage = React.lazy(() => import("./views/pages/publicPages/landingPage"));
const Deposit = React.lazy(() => import("./views/pages/privatePages/deposit"));

const Withdraw = React.lazy(() => import("./views/pages/privatePages/withdraw"));
const Register = React.lazy(() => import("./views/pages/publicPages/register"));
const Login = React.lazy(() => import("./views/pages/publicPages/login"));
const MobileNumberVerify = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumberVerify")
);
const MobileNumber = React.lazy(() =>
  import("./views/pages/publicPages/register/mobileNumber")
);
const Profile = React.lazy(() => import("./views/pages/privatePages/profile"));
const Sports = React.lazy(() => import("./views/pages/privatePages/sports"));
const ExchangeLobbyPage = React.lazy(() =>
  import("./views/pages/privatePages/exchange/ExchangeLobbyPage")
);
const MatchDetailPage = React.lazy(() =>
  import("./views/pages/privatePages/sports/matchDetailPage")
);
const MyBets = React.lazy(() => import("./views/pages/privatePages/myBets"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/publicPages/forgotPassword/resetPassword")
);

const App = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppDetails());
  }, []);
  useEffect(() => {
    document.title = appDetails?.SEO_TITLE || "";
  }, [appDetails]);
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoutes isAuth={isAuth}>
                <LandingPage />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Home />
                <ExchangeFund9Wicket />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/games-all"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Games />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/casino/:casinoslug/:gameslug"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <DynamicCasinoPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sports/betby"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <BetbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/cashier"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Cashier />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/mybets"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MyBets />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Deposit />
                <Footer whatsAppNumber={"919769691000"} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/sportsbook"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <PublicSportsBook />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/withdraw"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Withdraw />
                <Footer whatsAppNumber={"919769691000"} />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/register"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Register />
                <Footer />
              </PublicRoutes>
            }
          />
          <Route
            path="/sign-in"
            element={
              <PublicRoutes isAuth={isAuth}>
                <Login />
              </PublicRoutes>
            }
          />
          <Route
            path="/verify-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumberVerify />
              </PublicRoutes>
            }
          />
          <Route
            path="/mobile-number"
            element={
              <PublicRoutes isAuth={isAuth}>
                <MobileNumber />
              </PublicRoutes>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <Profile />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route path="test" element={<Sports />} />
          <Route
            // path="/sports/exchange"
            path="/sports"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <ExchangeLobbyPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/detail-page/:slug/:matchId/:marketId/:sportId"
            element={
              <ProtectedRoutes isAuth={isAuth}>
                <MatchDetailPage />
                <Footer />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ForgotPassword />
              </PublicRoutes>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoutes isAuth={isAuth}>
                <ResetPassword />
              </PublicRoutes>
            }
          />
          <Route path="/contactus" element={<Contact />} />
          <Route path="/fairplay" element={<FairPlay />} />
          <Route path="/gamesrule" element={<GameRule />} />
          <Route path="/privacypolicy" element={<Policy />} />
          <Route path="/responsiblegame" element={<Responsible />} />
          <Route path="/termsconditions" element={<TermsCondition />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
