export const API_URLS = {
  AUTH: {
    LOGIN_USER: "/login",
    REGISTER_USER: "/register",
    SEND_OTP_TO_MOBILE: "/sendOtpToMobile",
    MOBILE_VERIFICATION: "/mobileVerification",
    UPDATE_PASSWORD: "/update-password",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
  },
  USER: {
    ACCOUNT_STATEMENT: "/getAccountstatement",
    CREATE_WITHDRAW_REQUEST: "/withdrawRequest/create",
    CREATE_DEPOSIT: "/deposit/create",
    FLOXY_DEPOSIT: "/deposit/floxy",
    FASTPAYME_DEPOSIT: "/deposit/fastpayme",
    HPAY_DEPOST: "/deposit/hpay",
  },
  APP: {
    APP_DETAILS: "/getAppDetails",
    BANK_DETAILS: "/getBankAccountDetails",
    GET_BANNERS: "/getBanners",
  },
};
