import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { socket } from "../../../../utils/socketUtil";
import { asyncSocketEmit } from "../../../../utils/socketUtil";
import { Toaster } from "../../../../utils/toaster";
import { APP_CONST } from "../../../../config/const";
import { useSelector } from "react-redux";
const PublicSportsBook = () => {
  const [iframeUrl, setIframeUrl] = useState("");
  const isAuth = useSelector((state) => state.auth.isAuth);
  var width = window.screen.width;
  const isMobile = width < 800;
  document.body.style.setProperty("--header-background", `#050505`);

  useEffect(() => {
    if (isAuth) {
      getUrl();
    }
  }, [isAuth]);

  const getUrl = async () => {
    socket.emit(
      "get:gamblebeast_lobby",
      {
        mobile: isMobile,
      },
      (res) => {
        if (res?.status === "failed") {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        }
        if (res?.status === "success") {
          setIframeUrl(res?.data);
        }
      }
    );
  };

  return (
    <>
      <HeaderAfterLogin />
      <div style={{ height: "100vh" }}>
        <iframe
          src={iframeUrl}
          width="100%"
          style={{ height: "100%", border: "0" }}
        />
      </div>
    </>
  );
};
export default PublicSportsBook;
