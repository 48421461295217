import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import Loader from "../assets/images/games-tab/loader-new.gif";
import ProfileIcon from "../assets/svg/ProfileIcon";
import logo from "../assets/svg/logo_new.png";
import { useSelector } from "react-redux";
import { APP_CONST } from "../config/const";
import { socket } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import { useState } from "react";

const Footer = ({ whatsAppNumber = "919769691000" }) => {
  const { appDetails } = useSelector((state) => state.app);
  const [isLoader, setShowLoader] = useState(false);

  const handleExchangeLaunch = () => {
    console.log("handleExchangeLaunch", new Date());
    setShowLoader(true);
    socket.emit("get:spbwicket9_lobby", {}, (res) => {
      console.log("handleExchangeLaunch socket emit", res, new Date());
      if (res.data?.error === true) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        return false;
      }
      setShowLoader(false);
      window.open(res?.data?.data?.lobbyUrl, "_blank");
    });

    return () => {
      socket.off("get:spbwicket9_lobby");
    };
  };

  const footerOptions = [
    {
      id: 1,
      name: "Contact Us",
      redirectLink: "/contactus",
    },
    // {
    //   id: 2,
    //   name: "Privacy Policy",
    //   redirectLink: "/privacypolicy",
    // },
    {
      id: 3,
      name: "Responsible Gaming",
      redirectLink: "/responsiblegame",
    },
    // {
    //   id: 4,
    //   name: "Fair Play",
    //   redirectLink: "/fairplay",
    // },
    {
      id: 5,
      name: "Games Rules",
      redirectLink: "/gamesrule",
    },
    {
      id: 6,
      name: "Terms and Conditions",
      redirectLink: "/termsconditions",
    },
  ];
  return (
    <footer className="footer">
      <div className={isLoader ? "loading_img2" : "loading_img2 d-none"}>
        <img src={Loader} alt="Loader" />
      </div>
      <div className="container">
        <div className="footer-links mb-5">
          <ul>
            {footerOptions.map((item, index) => {
              return (
                <li key={index}>
                  <a href={item.redirectLink}>{item.name}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="footer-logo">
          {appDetails?.LOGO_URL && <img src={appDetails?.LOGO_URL || logo} />}
        </div>
        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages mb-4">
          <ul>
            <li>
              <img src="./images/master-card.png" />
            </li>
            <li>
              <img src="./images/visa-card.png" />
            </li>
          </ul>
        </div>

        <div className="secureGaming">
          <ul>
            <li>
              <img src="./images/GA-partner.png" />
            </li>
            <li>
              <img src="./images/gamecare-partner.png" />
            </li>
            <li>
              <img src="./images/mga-partner.png" />
            </li>
            <li>
              <img src="./images/age18.png" />
            </li>
          </ul>
        </div>

        <div className="social-icons">
          <ul>
            <li>
              <div
                onClick={() =>
                  window.open(`https://wa.me/${whatsAppNumber}`, "_blank")
                }
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </div>
            </li>
            <li>
              <div
                onClick={() =>
                  window.open(`https://t.me/daddypaynet`, "_blank")
                }
              >
                <img src={Telegram} alt="Telegram Icon" />
              </div>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100086637202454"
              >
                <img src={Facebook} alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/daddypaydaddy/?igshid=YmMyMTA2M2Y%3D"
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-md-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a
                href="javascript:void(0)"
                onClick={() => handleExchangeLaunch()}
              >
                <SportsIcon />
                <span>Sports</span>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi">
                <GamesIcon />
                <span>Casino</span>
              </a>
            </li>
            <li>
              <a className="active" href="/home">
                <HomeIcon />
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <CashierIcon />
                <span>Cashier</span>
              </a>
            </li>
            <li>
              <a
                href={`https://wa.me/${appDetails?.WHATS_APP_URL}`}
                target="_blank"
              >
                {/* <HeartIcon /> */}
                <ProfileIcon />
                <span>Support</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
